<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            9. Запрещенные действия при эксплуатации эвакуационных путей, эвакуационных и аварийных выходов
          </v-card-title>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog1"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/doorway.svg"
                  max-width="80"
                  src="img/material/doorway.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Устраивать пороги и снимать двери эвакуационных выходов
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
               <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Устраивать на путях эвакуации пороги (за исключением порогов в дверных проемах);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Снимать предусмотренные проектной документацией двери эвакуационных выходов из поэтажных коридоров, холлов, фойе, вестибюлей, тамбур-шлюзов, тамбуров и лестничных клеток, а также другие двери, препятствующие распространению пожара на путях эвакуации.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1= false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog2"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center "
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/door-box.svg"
                  max-width="80"
                  src="img/material/door-box.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Загромождать пути и выходы
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
               <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Загромождать эвакуационные пути и выходы (в том числе проходы, коридоры, тамбуры, галереи, лифтовые холлы, лестничные площадки, марши лестниц, двери, эвакуационные люки) материалами, изделиями, оборудованием, производственными отходами, мусором и другими предметами, а также блокировать двери эвакуационных выходов.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog3"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/door-volume.svg"
                  max-width="80"
                  src="img/material/door-volume.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Изменять направление открывания дверей
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
               <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Изменять направление открывания дверей, за исключением дверей, открывание которых не нормируется или к которым предъявляются иные требования
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog4"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/exit.svg"
                  max-width="80"
                  src="img/material/exit.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Ухудшать видимость световых оповещателей
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
               <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Закрывать и ухудшать видимость световых оповещателей, обозначающих эвакуационные выходы, и эвакуационных знаков пожарной безопасности
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog4 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog5"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/dimension.svg"
                  max-width="80"
                  src="img/material/dimension.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Изменять объемно-планировочные решения
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
               <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Изменять объемно-планировочные решения и размещение инженерных коммуникация оборудования, в результате которых:
                  <v-card-text class="title font-weight-light">
                    <v-icon size="10" color="red" class="pl-10 mr-3">mdi-circle</v-icon>
                    ограничивается доступ к системам обеспечения ПБ (огнетушителями, пожарным кранам);
                  </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon size="10" color="red" class="pl-10 mr-3">mdi-circle</v-icon>
                        уменьшается зона действия автоматических систем противопожарной защиты автоматической пожарной сигнализации, стационарной автоматической установки пожаротушения, системы дымоудаления, системы оповещения и управления эвакуацией).
                    </v-card-text>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Не допускается устройство эвакуационных выходов через разгрузочные зоны, за исключением выходов из помещений с одиночными рабочими местами, связанными с обслуживанием погрузочно-разгрузочных работ.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog5 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog6"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/window.svg"
                  max-width="80"
                  src="img/material/window.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Закрывать жалюзи, остеклять балконы
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Закрывать жалюзи, остеклять балконы (открытые переходы наружных воздушных зон), лоджии и галереи, ведущие к незадымляемым лестничным клеткам;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Устанавливать глухие решетки на окнах и приямках у окон подвалов, являющихся аварийными выходами, за исключением специально предусмотренных случаем пожарной безопасности.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog6 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog7"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/wardrobe.svg"
                  max-width="80"
                  src="img/material/wardrobe.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Устраивать в тамбурах выходов гардеробы и др.
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Устраивать в тамбурах выходов сушилки и вешалки для одежды, гардеробы, хранить (в том числе временно) инвентарь и материалы.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog7 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog8"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/exit-width.svg"
                  max-width="80"
                  src="img/material/exit-width.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Фиксировать открытыми самозакрывающиеся двери
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Фиксировать самозакрывающихся двери лестничных клеток, коридоров, холлов и тамбуров в открытом положении (если для этих целей не используются устройства, автоматически срабатывающие при пожаре), а также снимать их
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog8 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog9"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="200"
                  >
                <v-img
                  lazy-src="img/material/gate.svg"
                  max-width="80"
                  src="img/material/gate.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                 Устанавливать автоматические устройства без возможности открыть вручную
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                <span class="red--text font-weight-bold">ЗАПРЕЩЕНО:</span>
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                Устанавливать раздвижные и подъемно-опускные двери и ворота, вращающиеся двери и турникеты без возможности вручную открыть и заблокировать их в открытом состоянии при отсутствии иных путей эвакуации или технических решений.
              </v-card-text>

               <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                Допускается в дополнение к ручному способу применение автоматического или дистанционного способа открывания и блокирования устройств.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog9 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null,
    dialog4: null,
    dialog5: null,
    dialog6: null,
    dialog7: null,
    dialog8: null,
    dialog9: null
  }),
  components: {
    Hint
  }
}
</script>
